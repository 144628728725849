<template>
  <div class="form-container">
    <img class="logo" src="../assets/logo.png">
    <h3 class="form-title">Únete a RETO FILMS<br>
      Somos una asociación de jóvenes cineastas de Salamanca con intención de generar una industria cinematográfica independiente en la provincia.</h3>
    <form @submit.prevent="handleSubmit" class="registration-form">
      <div class="form-field" v-for="(field, index) in fields" :key="index">
        <input 
          :type="field.type" 
          :placeholder="field.placeholder" 
          v-model="formData[field.model]" 
          required
          @focus="closeDropdowns"
        />
        <span class="asterisk">*</span>
      </div>
      <div class="form-field">
        <div class="custom-select-wrapper" :class="{ open: dropdownOpen }">
          <div class="custom-select" @click="toggleDropdown">
            Provincias de actuación
            <span class="arrow" :class="{ open: dropdownOpen }">&#9662;</span>
          </div>
          <div class="custom-select-options" @click.stop @keydown="navigateDropdown">
            <div class="search-container">
              <input 
                type="text" 
                placeholder="Buscar..." 
                v-model="searchQuery" 
                class="search-input"
                ref="searchInput"
                @input="filterOptions"
                @keydown.down.prevent="moveDown"
                @keydown.up.prevent="moveUp"
              />
              <span class="clear-button" @click="clearSearch">&#10005;</span>
            </div>
            <label 
              v-for="(option, index) in filteredProvinces" 
              :key="option" 
              :class="{ active: activeIndex === index }"
            >
              <input 
                type="checkbox" 
                :value="option" 
                v-model="formData.provincias" 
              />
              {{ option }}
            </label>
          </div>
        </div>
        <span class="asterisk">*</span>
      </div>
      <div class="form-field">
        <div class="custom-select-wrapper" :class="{ open: dropdownOpenAptitudes }">
          <div class="custom-select" @click="toggleDropdownAptitudes">
            Cargos  
            <span class="arrow" :class="{ open: dropdownOpenAptitudes }">&#9662;</span>
          </div>
          <div class="custom-select-options" @click.stop @keydown="navigateDropdownAptitudes">
            <div class="search-container">
              <input 
                type="text" 
                placeholder="Buscar..." 
                v-model="searchQueryAptitudes" 
                class="search-input"
                ref="searchInputAptitudes"
                @input="filterOptionsAptitudes"
                @keydown.down.prevent="moveDownAptitudes"
                @keydown.up.prevent="moveUpAptitudes"
              />
              <span class="clear-button" @click="clearSearchAptitudes">&#10005;</span>
            </div>
            <div v-for="(option, index) in filteredAptitudes" :key="option">
              <div 
                v-if="isCategory(option)" 
                class="category"
              >
                {{ option }}
              </div>
              <label 
                v-else 
                :class="{ active: activeIndexAptitudes === index }"
              >
                <input 
                  type="checkbox" 
                  :value="option" 
                  v-model="formData.aptitudes" 
                />
                {{ option }}
              </label>
            </div>
          </div>
        </div>
        <span class="asterisk-invisible">*</span>
      </div>
      <div class="form-field">
        <textarea 
          placeholder="Explica brevemente por qué quieres formar parte de reto films y cuales son tus objetivos."
          v-model="formData.additionalNotes"
        ></textarea>
        <span class="transparent-asterisk">*</span>
      </div>
      <div class="form-field">
        <input 
          type="text" 
          placeholder="¿Tienes videobook? ¡Añade el enlace!" 
          v-model="formData.videobook"
        />
        <span class="asterisk-invisible">*</span>
      </div>
      
      <div class="form-field">
        <div class="file-upload-wrapper">
          <label for="curriculum" class="curriculum-label">¿Tienes curriculum? ¡Sube el PDF!</label>
          <button type="button"  class="custom-upload-button" @click="triggerFileInput">Seleccionar archivo</button>
          <input 
            id="curriculum"
            type="file" 
            accept=".pdf"
            ref="fileInput"
            @change="handleFileUpload"
          />
        </div>
        <span class="asterisk-invisible">*</span>
      </div>
      <div class="empty-space">

      </div>
      <div class="form-field checkbox-field">
        <label class="checkbox-label">
          <input type="checkbox" v-model="formData.terms" required>
          Acepto los&nbsp;<a href="/terms" target="_blank"> términos y condiciones</a>.
        </label>
        <span class="asterisk">*</span>
      </div>
      <div class="form-field checkbox-field">
        <label class="checkbox-label">
          <input type="checkbox" v-model="formData.communications" required>
          Acepto recibir comunicaciones sobre futuros proyectos y colaboraciones.
        </label>
        <span class="asterisk">*</span>
      </div>
      <div class="form-field checkbox-field">
        <label class="checkbox-label">
          <input type="checkbox" v-model="formData.marketing">
          Acepto recibir información de eventos y publicidad. (Opcional)
        </label>
        <span class="transparent-asterisk">*</span>
      </div>
      <div class="empty-space-2">

      </div>
      <input type="submit" value="Enviar" class="submit-button" :disabled="!formIsValid"/>
      <div class="bottom-space">

      </div>
    </form>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      dropdownOpen: false,
      dropdownOpenAptitudes: false,
      dropdownOpenIntereses: false,
      searchQuery: '',
      searchQueryAptitudes: '',
      searchQueryIntereses: '',
      activeIndex: -1,
      activeIndexAptitudes: -1,
      activeIndexIntereses: -1,
      formData: {
        nombre: '',
        apellidos: '',
        dni: '',
        telefono: '',
        email: '',
        provincias: [],
        aptitudes: [],
        intereses: [],
        additionalNotes: '',
        curriculum: null,
        videobook: '',
        terms: false,
        communications: false,
        marketing: false
      },
      fields: [
        { type: 'text', placeholder: 'Nombre', model: 'nombre' },
        { type: 'text', placeholder: 'Apellidos', model: 'apellidos' },
        { type: 'text', placeholder: 'DNI o NIE', model: 'dni' },
        { type: 'tel', placeholder: 'Teléfono de contacto', model: 'telefono' },
        { type: 'email', placeholder: 'Correo electrónico', model: 'email' },
      ],
      provinces: [
        "A Coruña", "Álava", "Albacete", "Alicante", "Almería", "Asturias", "Barcelona", "Cádiz", "Cantabria", "Castellón", "Ceuta", "Ciudad-Real", 
        "Córdoba", "Cuenca", "Extremadura", "Girona", "Granada", "Guadalajara", "Guipúzcoa", "Huelva", "Huesca", "Jaén", "Las Palmas de Gran Canaria", 
        "León", "Lleida", "Logroño", "Lugo", "Madrid", "Melilla", "Murcia", "Navarra", "Ourense", "Pontevedra", "Salamanca", "Santa Cruz de Tenerife", 
        "Sevilla", "Soria", "Tarragona", "Teruel", "Toledo", "Valencia", "Valladolid", "Vizcaya", "Zamora", "Zaragoza"
      ],
      aptitudes: [
        "Guion", "Interpretación", "Actor o actriz", "Extras y figurantes", "Especialistas", "Actor o actriz de voz", 
        "Producción", "Productor", "Productor ejecutivo", "Productor de campo", "Auxiliares y meritorios", 
        "Dirección", "Director", "Ayudante de dirección", "Script o continuista", "Director de casting", 
        "Fotografía", "Director de fotografía", "Cámara", "Foquista", "Auxiliares y ayudantes de cámara", 
        "Gaffer / jefe de eléctricos", "Eléctricos", "Foto fija", "Grip / maquinista", "Colorista", 
        "Efectos en set", "Operador de dron", "Sonido", "Pertiguista", "Jefe de sonido", "Diseñador de sonido", 
        "Mezclador", "Grabador de foley", "Editor de diálogos", "Arte", "Director de arte", "Ambientador", 
        "Escenógrafo", "Vestuario", "Figurinista", "Sastre", "Asistente de vestuario", "Maquillaje", 
        "Caracterizador", "Maquillador", "Peluquero", "Edición", "Montador", "Ayudantes y asistentes", 
        "Subtitulado", "Créditos", "VFX", "Otros", "Distribuidor", "Marketing y publicidad", "Exhibidor", 
        "Filmmaker para RRSS", "Filmmaker de making of", "Cartelería"
      ],
    };
  },
  computed: {
    sortedProvinces() {
      return [...this.provinces].sort((a, b) => a.localeCompare(b, 'es', { sensitivity: 'base' }));
    },
    filteredProvinces() {
      return this.sortedProvinces.filter(option =>
        option.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").startsWith(
          this.searchQuery.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        )
      );
    },
    filteredAptitudes() {
      return this.aptitudes.filter(option =>
        option.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").startsWith(
          this.searchQueryAptitudes.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        )
      );
    },
    filteredIntereses() {
      return this.aptitudes.filter(option =>
        option.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").startsWith(
          this.searchQueryIntereses.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        )
      );
    },
    formIsValid() {
      return (
        this.formData.nombre &&
        this.formData.apellidos &&
        this.formData.dni &&
        this.formData.telefono &&
        this.formData.email &&
        this.formData.provincias.length > 0 &&
        this.formData.terms &&
        this.formData.communications
      );
    }
  },
  methods: {
    toggleDropdown(event) {
      event.stopPropagation();
      this.dropdownOpen = !this.dropdownOpen;
      if (this.dropdownOpen) {
        this.dropdownOpenAptitudes = false;
        this.dropdownOpenIntereses = false;
        nextTick(() => {
          this.$refs.searchInput.blur();  // Prevent keyboard from opening
        });
      }
    },
    toggleDropdownAptitudes(event) {
      event.stopPropagation();
      this.dropdownOpenAptitudes = !this.dropdownOpenAptitudes;
      if (this.dropdownOpenAptitudes) {
        this.dropdownOpen = false;
        this.dropdownOpenIntereses = false;
        nextTick(() => {
          this.$refs.searchInputAptitudes.blur();  // Prevent keyboard from opening
        });
      }
    },
    toggleDropdownIntereses(event) {
      event.stopPropagation();
      this.dropdownOpenIntereses = !this.dropdownOpenIntereses;
      if (this.dropdownOpenIntereses) {
        this.dropdownOpen = false;
        this.dropdownOpenAptitudes = false;
        nextTick(() => {
          this.$refs.searchInputIntereses.blur();  // Prevent keyboard from opening
        });
      }
    },
    closeDropdowns() {
      this.dropdownOpen = false;
      this.dropdownOpenAptitudes = false;
      this.dropdownOpenIntereses = false;
      this.searchQuery = '';
      this.searchQueryAptitudes = '';
      this.searchQueryIntereses = '';
      this.activeIndex = -1;
      this.activeIndexAptitudes = -1;
      this.activeIndexIntereses = -1;
    },
    filterOptions() {
      this.activeIndex = -1;
    },
    filterOptionsAptitudes() {
      this.activeIndexAptitudes = -1;
    },
    filterOptionsIntereses() {
      this.activeIndexIntereses = -1;
    },
    moveDown() {
      if (this.activeIndex < this.filteredProvinces.length - 1) {
        this.activeIndex++;
        this.scrollIntoView('.custom-select-options', this.activeIndex);
      }
    },
    moveDownAptitudes() {
      if (this.activeIndexAptitudes < this.filteredAptitudes.length - 1) {
        this.activeIndexAptitudes++;
        this.scrollIntoView('.custom-select-options', this.activeIndexAptitudes);
      }
    },
    moveDownIntereses() {
      if (this.activeIndexIntereses < this.filteredIntereses.length - 1) {
        this.activeIndexIntereses++;
        this.scrollIntoView('.custom-select-options', this.activeIndexIntereses);
      }
    },
    moveUp() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
        this.scrollIntoView('.custom-select-options', this.activeIndex);
      }
    },
    moveUpAptitudes() {
      if (this.activeIndexAptitudes > 0) {
        this.activeIndexAptitudes--;
        this.scrollIntoView('.custom-select-options', this.activeIndexAptitudes);
      }
    },
    moveUpIntereses() {
      if (this.activeIndexIntereses > 0) {
        this.activeIndexIntereses--;
        this.scrollIntoView('.custom-select-options', this.activeIndexIntereses);
      }
    },
    scrollIntoView(containerSelector, index) {
      nextTick(() => {
        const container = this.$el.querySelector(containerSelector);
        const activeElement = container.querySelectorAll('label, .category')[index];
        if (activeElement) {
          activeElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
      });
    },
    clearSearch() {
      this.searchQuery = '';
    },
    clearSearchAptitudes() {
      this.searchQueryAptitudes = '';
    },
    clearSearchIntereses() {
      this.searchQueryIntereses = '';
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.formData.curriculum = file;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async handleSubmit() {
  try {
    const formData = new FormData();

    for (const key in this.formData) {
      if (Array.isArray(this.formData[key])) {
        formData.append(key, this.formData[key].join(', '));
      } else if (typeof this.formData[key] === 'boolean') {
        formData.append(key, this.formData[key] ? '1' : '0');
      } else {
        formData.append(key, this.formData[key]);
      }
    }

    const response = await fetch('https://retofilms.com/submit_form.php', {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      console.log('Form submitted successfully');
      this.router.push('/success');  // Correct path for success page
    } else {
      console.error('Form submission error:', response.statusText);
    }
  } catch (error) {
    console.error('Form submission error:', error);
  }
},
    isCategory(option) {
      const categories = [
        "Interpretación", "Producción", "Dirección", "Fotografía", "Sonido", "Arte", 
        "Vestuario", "Maquillaje", "Edición", "Otros"
      ];
      return categories.includes(option);
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdowns);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdowns);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
  background-color: #333333;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 1rem;
}

.form-title {
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-left: 1%;
  margin-top: 30px;
  margin-bottom: -15px;
  border-radius: 10px;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.7rem;
  color: #FFFFFF;
}



.registration-form {
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000; /*Background color Rectangle arround the form*/
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.form-field {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  margin-left: 5px;
}

input[type="text"], input[type="tel"], input[type="email"], textarea {
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  background-color: #000000; /*Color background filds*/
  color: #FFFFFF;
}

input::placeholder, textarea::placeholder {
  color: #e6e6e6; /* Placeholder color */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: #444444 !important;
  color: #FFFFFF !important;
  -webkit-box-shadow: 0 0 0px 1000px #444444 inset !important;
  box-shadow: 0 0 0px 1000px #444444 inset !important;
  -webkit-text-fill-color: #FFFFFF !important; /* Ensure autofill text is white */
}

textarea {
  height: calc(3 * (10px + 1em));
}

.custom-select-wrapper {
  position: relative;
  width: 100%;
}

.custom-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000000; /*Background color custom fields*/
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  color: #e6e6e6; /* Custom select text color */
}

.custom-select .arrow {
  padding-left: 10px;
  transition: transform 0.3s ease;
}

.custom-select .arrow.open {
  transform: rotate(180deg);
}

.custom-select-options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #000000;
  border: 1px solid #ccc;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 10;
  max-height: 260px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  color: #e6e6e6; /* Custom select options color */
}

.custom-select-wrapper.open .custom-select-options {
  display: block;
}

.custom-select-options label, .custom-select-options .category {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}

.custom-select-options label.active {
  background-color: #E57373; /* Light red background for active options */
  color: white;
}

.custom-select-options input[type="checkbox"] {
  margin-right: 7px;
}

.search-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-input {
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  background-color: #444444;
  color: #FFFFFF;
}

.search-input::placeholder {
  color: #e6e6e6; /* Search input placeholder color */
}

.clear-button {
  cursor: pointer;
  padding: 0px;
  font-size: 1.2em;
  color: #666;
  margin-left: -30px;
  margin-top: -10px;
}

.asterisk {
  color: red;
  padding-left: 5px;
}
.asterisk-invisible{
  opacity: 0;
  color: red;
  padding-left: 5px;
}

.transparent-asterisk {
  color: transparent;
  padding-left: 5px;
}

.submit-button {
  padding: 10px 20px;
  border: none;
  background-color: #E57373; /* Soft red background for the submit button */
  color: white;
  cursor: pointer;
  margin-top: 10px;
  width: 50%;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover:enabled {
  background-color: #ef5350; /* Darker red on hover */
}

.category {
  font-weight: bold;
  border-top: 1px solid #ccc;
  margin-top: 8px;
  padding-top: 8px;
}

.category:first-of-type {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}

.checkbox-field {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.checkbox-label {
  display: flex;
  align-items: flex-start; /* Aligns items to the top */
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  color: #e6e6e6; /* Checkbox labels color */
}

.checkbox-label a {
  color: #E57373; /* Soft red for the link */
}

.checkbox-field input[type="checkbox"] {
  accent-color: #e6e6e6; /* Checkbox color */
}

.checkbox-field a:hover {
  text-decoration: underline;
}
input::placeholder, textarea::placeholder {
  color: rgba(230, 230, 230, 0.5); /* 70% opacity */
}

.logo {
  max-width: 10rem;
  margin-top: 2rem;
}

.curriculum-label {
  color: #e6e6e6; /* Matching the color of the other labels */
}

.file-upload-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.file-upload-wrapper {
  text-align: left;
  display: flex;
  align-items: center;
  background-color: #000000;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.file-upload-wrapper label {
  color: #e6e6e6;
  margin-right: 10px;
}

.file-upload-wrapper input[type="file"] {
  background-color: #000000;
  color: #000000;
  border: none;
  padding: 0;
  width: 0;
  margin-right: 0;

}

.custom-upload-button{
  
  background-color: #000000;
  color: #ccc;
  border-color: #ccc;
  margin-left: 0;
}

.bottom-space{
  margin-bottom: 4rem;
}

.empty-space{
  margin-bottom: 0.6rem;
}

.empty-space-2{
  margin-bottom: 3rem;
}
</style>
